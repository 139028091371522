import './webpack-imports';
import './sentry';
import './polyfills';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Cookie from 'js-cookie';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import { Cursor } from './components/cursor';
import pixelatedEffect from './inits/pixelated-effect';
import navigation from './inits/navigation';
import floatedLabels from './inits/floated-labels';
import morph from './inits/morph';
import maskedEffect from './inits/masked-effect';
import sectionsCounter from './inits/sections-counter';
import productsCounter from './inits/products-counter';
import slider from './inits/slider';
import supportSlider from './inits/support-slider';
import ajaxForms from './inits/ajax-forms';
import popupForms from './inits/popup-forms';
import labForm from './inits/lab-form';
import select from './inits/select';
import sectionTransition from './inits/section-transition';
import contactPopup from './inits/contact-popup';
import { initialAnimation } from './animations/initial';
import scrolling from './animations/scrolling';
import revealOnScroll from './animations/reveal-on-scroll';
import { viewport } from './viewport';
import './custom-elements';
import './inits/direction-aware-hover';
import './inits/lazy-load';
import './inits/map';
import './inits/error-page';

document.documentElement.classList.add('js-ready');

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

calculateScrollbarWidth();
pixelatedEffect.init();
morph.init();
sectionTransition.init();
navigation.init();
productsCounter.init();
slider.init();
supportSlider.init();
sectionsCounter.init();
scrolling.init();
revealOnScroll.init();

floatedLabels.init();
ajaxForms.init();
popupForms.init();
labForm.init();
contactPopup.init();
select.init();
maskedEffect.init();

let cursor: any;

function attemptToInitCursor() {
    if (matchMedia('(pointer: fine)').matches && !cursor) {
        cursor = Cursor();
        cursor.init();
    }
}

attemptToInitCursor();
window.addEventListener('resize', attemptToInitCursor);

const firstSection = document.querySelector<HTMLElement>('.js-first-section');
const firstScreenPixelatedTexts = firstSection
    ? Array.from(firstSection.querySelectorAll<HTMLElement>('.js-gl-text'))
    : [];

document.addEventListener(
    'adult-confirmed',
    () => {
        if (Cookie.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
            import('./custom-elements/CookiesAgreement/CookiesAgreement' /* webpackChunkName: "CookiesAgreement" */);
        }
    },
    { once: true },
);

document.addEventListener(
    'pixelated-initialized',
    () => {
        firstScreenPixelatedTexts.forEach((el) => {
            const pixelated = pixelatedEffect.getInstanceByElement(el);
            const pixelSize = el.dataset.pixelSize ? parseFloat(el.dataset.pixelSize) : 60;

            if (pixelated) {
                pixelatedEffect.pixelate(el, pixelSize, undefined, true);
                gsap.fromTo(
                    pixelated.material.uniforms,
                    { maskLine: 0 },
                    { duration: 1.5, maskLine: 1, ease: 'expo.inOut' },
                );
            }
        });

        setTimeout(() => {
            document.body.classList.add('is-revealed');
            initialAnimation.play();

            function depixelateTexts() {
                firstScreenPixelatedTexts.forEach((el) => {
                    pixelatedEffect.depixelate(el, 1.1).then(() => {
                        pixelatedEffect.destroyCarpetByElement(el);
                    });
                });
            }

            const adultCheckEl = document.querySelector<HTMLElement>('.js-adult-check-container');
            const adultCheckElItem = document.querySelector<HTMLElement>('.js-adult-check');
            const adultCheckNoBtn = document.querySelector<HTMLElement>('.js-adult--no');
            const adultCheckYesBtn = document.querySelector<HTMLElement>('.js-adult--yes');

            if (Cookie.get('IS_ADULT') === 'true') {
                document.dispatchEvent(new Event('adult-confirmed'));
                adultCheckEl?.remove();
                setTimeout(() => {
                    depixelateTexts();
                }, 300);
            } else {
                const offset = (viewport.width / 48) * 12;
                let rAF: number;

                document.body.classList.add('no-scroll');
                window.scrollTo({ top: 0, behavior: 'auto' });

                if (matchMedia('(min-width: 1025px)').matches) {
                    gsap.set('.js-main-screen-bottom', { x: offset });
                }

                function animate() {
                    for (let i = 0; i < firstScreenPixelatedTexts.length; i++) {
                        const el = firstScreenPixelatedTexts[i];
                        const pixelated = pixelatedEffect.getInstanceByElement(el);

                        if (pixelated) {
                            pixelated.carpet.resize();
                        }
                    }

                    rAF = requestAnimationFrame(animate);
                }

                adultCheckNoBtn?.addEventListener('click', () => {
                    window.history.back();
                });

                adultCheckYesBtn?.addEventListener('click', () => {
                    Cookie.set('IS_ADULT', 'true', { expires: 365 });
                    depixelateTexts();
                    document.dispatchEvent(new Event('adult-confirmed'));

                    const duration = 1.5;
                    const easing = 'expo.inOut';

                    if (matchMedia('(min-width: 1025px)').matches) {
                        gsap.to('.js-main-screen-bottom', {
                            duration,
                            x: 0,
                            ease: easing,
                            onStart: animate,
                            onComplete: () => {
                                cancelAnimationFrame(rAF);
                                gsap.set('.js-main-screen-bottom', { clearProps: 'all' });
                            },
                        });
                    }

                    if (matchMedia('(max-width: 1024px)').matches) {
                        document.body.classList.remove('no-scroll');
                    }

                    adultCheckEl?.classList.add('is-leaving');

                    gsap.to(adultCheckElItem, {
                        duration: duration - 0.1,
                        clipPath: 'inset(0% 100% 0% 0%)',
                        ease: easing,
                        onComplete: () => {
                            adultCheckEl?.remove();
                        },
                    });
                });
            }
        }, 300);
    },
    { once: true },
);

// module.hot?.accept();
