import gsap from 'gsap';
import { hideElement, showElement } from '../animations/reveal';
import { createSliderCounter } from '../components/app-slider/counter';
import { getOffsetTop, isInViewport } from '../utils/dom';

export function withResponse(instance: any, map: Map<Element, any>) {
    let currentAnimationValue: number | null;

    const data = {
        direction: 'left',
    };

    function restoreClipPath(target: gsap.TweenTarget, from: number) {
        if (target) {
            gsap.fromTo(
                target,
                { clipPath: `inset(-10% ${from}% -10% -10%)` },
                {
                    clipPath: `inset(-10% -10% -10% -10%)`,
                    display: 'inline-block',
                },
            );
        }
    }

    instance._hammer.on('panmove', (event: any) => {
        if (instance.isAnimating) return;

        data.direction = event.deltaX < 0 ? 'left' : 'right';
        const elements = map.get(instance.slides[instance.activeIndex]);
        const value = -10 + event.distance * (matchMedia('(pointer: coarse)').matches ? 0.3 : 0.1);
        currentAnimationValue = value;

        if (elements) {
            gsap.set([elements.titleEl, elements.imgEl, elements.textEl, elements.descriptionEl, elements.tagsItems], {
                clipPath:
                    data.direction === 'left' ? `inset(-10% ${value}% -10% -10%)` : `inset(-10% -10% -10% ${value}%)`,
                display: 'inline-block',
            });
        }
    });

    instance._hammer.on('panend', () => {
        if (instance.isAnimating) return;

        const elements = map.get(instance.slides[instance.activeIndex]);

        setTimeout(() => {
            if (!instance.isAnimating) {
                [elements.titleEl, elements.imgEl, elements.textEl, elements.descriptionEl, elements.tagsItems].forEach(
                    (el) => {
                        if (currentAnimationValue) {
                            restoreClipPath(el, currentAnimationValue);
                        }
                    },
                );
            }
        }, 1);
    });

    return data;
}

function init() {
    const header = document.querySelector<HTMLElement>('.js-header');

    Array.from(document.querySelectorAll<HTMLElement>('.js-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.js-slide'));
        const map = new Map<
            Element,
            {
                titleEl: Element | null;
                descriptionEl: Element | null;
                imgEl: Element | null;
                textEl: Element | null;
                tagsItems: Element[];
            }
        >();

        if (slides.length > 1) {
            const Slider = await import('../components/app-slider' /* webpackChunkName: "Slider" */).then(
                (m) => m.default,
            );
            let responseData: ReturnType<typeof withResponse> | null;

            slides.forEach((el) => {
                map.set(el, {
                    titleEl: el.querySelector('.js-slider-title'),
                    descriptionEl: el.querySelector('.js-slider-description'),
                    imgEl: el.querySelector('.js-slider-img'),
                    textEl: el.querySelector('.js-slider-text'),
                    tagsItems: Array.from(el.querySelectorAll('.js-slider-tags-item')),
                });
            });

            function slideLeave(instance: any): Promise<void> {
                return new Promise((resolve) => {
                    const elements = map.get(instance.slides[instance.activeIndex]);

                    if (elements) {
                        const tl = gsap.timeline({ onComplete: resolve });
                        tl.add(
                            hideElement(
                                elements.titleEl,
                                undefined,
                                undefined,
                                true,
                                responseData?.direction || 'left',
                            ),
                        )
                            .add(
                                hideElement(
                                    elements.tagsItems,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.1,
                            )
                            .add(
                                hideElement(
                                    elements.descriptionEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.1,
                            )
                            .add(
                                hideElement(
                                    elements.imgEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.2,
                            )
                            .add(
                                hideElement(
                                    elements.textEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.3,
                            );
                    } else {
                        resolve();
                    }
                });
            }

            function slideEnter(instance: any): Promise<void> {
                return new Promise((resolve) => {
                    const elements = map.get(instance.slides[instance.activeIndex]);

                    if (elements) {
                        const tl = gsap.timeline({ onComplete: resolve });
                        tl.add(
                            showElement(
                                elements.titleEl,
                                undefined,
                                undefined,
                                true,
                                responseData?.direction || 'left',
                            ).play(),
                        )
                            .add(
                                showElement(
                                    elements.tagsItems,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.1,
                            )
                            .add(
                                showElement(
                                    elements.descriptionEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.1,
                            )
                            .add(
                                showElement(
                                    elements.imgEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.2,
                            )
                            .add(
                                showElement(
                                    elements.textEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.3,
                            );
                    } else {
                        resolve();
                    }
                });
            }

            const slider = new Slider(el, {
                pagination: {
                    render: (_, index) =>
                        `<button class="formula-pagination-btn">
                            <span class="formula-pagination-btn__text">${slides[index].dataset.name || ''}</span>
                        </button>`,
                },
                slideChangeLeaveTransition: slideLeave,
                slideChangeEnterTransition: slideEnter,
            });

            responseData = withResponse(slider, map);
            createSliderCounter(slider);
            slider.slidesContainer?.classList.add('js-cursor--arrowed');
            document.dispatchEvent(new Event('hoverables-added'));

            Array.from(el.querySelectorAll('.formula-pagination-btn')).forEach((btn) => {
                btn.addEventListener('click', () => {
                    if (
                        btn.classList.contains('is-active') ||
                        (slider.slidesContainer && isInViewport(slider.slidesContainer))
                    ) {
                        return;
                    }

                    const headerHeight = header?.getBoundingClientRect().height || 0;

                    gsap.to(window, {
                        duration: 1,
                        scrollTo: getOffsetTop(el) - headerHeight - 70,
                        ease: 'expo.inOut',
                    });
                });
            });
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
