import Slider from './index';
import gsap from 'gsap';
import { withLeadingZero } from '../../utils/strings';

export function createSliderCounter(
    sliderInstance: Slider,
    template = '<div class="app-slider-counter"><span class="js-slider-counter--current"></span> - <span class="js-slider-counter--total"></span></div>',
) {
    const container = sliderInstance.container.querySelector('.js-slider-counter');

    if (!container) return;

    container.innerHTML = `
            <span class="section-counter__current-container">
                <span class="section-counter__current--current js-section-counter--current">${withLeadingZero(
                    sliderInstance.activeIndex + 1,
                )}</span>
                <span class="section-counter__current--next js-section-counter--next"></span>
            </span>
            — <span class="section-counter__total">${withLeadingZero(sliderInstance.slides.length)}</span>
        `;

    const counterCurrentEl = container.querySelector('.js-section-counter--current');
    const counterNextEl = container.querySelector('.js-section-counter--next');

    sliderInstance.container.appendChild(container);

    sliderInstance.on('change-leave', ({ detail: { currentIndex, nextIndex } }) => {
        const nextCount = `${withLeadingZero(nextIndex + 1)}`;

        if (counterNextEl) {
            counterNextEl.textContent = nextCount;
        }

        gsap.to([counterCurrentEl, counterNextEl], {
            duration: 0.4,
            // yPercent: () => `${currentY > previousY ? '+' : '-'}=100`,
            yPercent: () => `+=100`,
            onComplete: () => {
                if (counterCurrentEl) {
                    counterCurrentEl.textContent = nextCount;
                    gsap.set([counterCurrentEl, counterNextEl], { clearProps: 'all' });
                }
            },
            overwrite: true,
        });
    });
}
