import gsap from 'gsap';
import Cookie from 'js-cookie';
// import { createFocusTrap } from 'focus-trap';
import pixelatedEffect from './pixelated-effect';
import { initialAnimation } from '../animations/initial';
import { hideElement, showElement } from '../animations/reveal';
import { getOffsetTop } from '../utils/dom';
import keyCodes from '../utils/keyCodes';
import { scrollToSection } from './navigation';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const animationDuration = 1;
const sections = Array.from(document.querySelectorAll<HTMLElement>('.js-section'));
const header = document.querySelector<HTMLElement>('.js-header');
const mainTextBlock = document.querySelector<HTMLElement>('.js-main-text-block');
const mainTitle = document.querySelector<HTMLElement>('.js-main-title');
const mainText = document.querySelector<HTMLElement>('.js-main-screen-bottom-text');
const contentAnimationElements = Array.from(
    document.querySelectorAll<HTMLElement>('.js-content-animation, .section-text-block p'),
);
const mainScreenAside = document.querySelector<HTMLElement>('.js-main-screen__aside');
const firstSection = document.querySelector<HTMLElement>('.js-first-section');
const appContent = document.querySelector<HTMLElement>('.js-app-content');
let isFirstSectionActive = true;
let isAnimating = false;
let isInitialLoad = true;
// const firstScreenFocusTrap = header && firstSection ? createFocusTrap([header, firstSection]) : null;
// firstScreenFocusTrap?.deactivate();

const popups = Array.from(document.querySelectorAll<LitPopupElement>('app-lit-popup'));

function onWheel(event: WheelEvent) {
    if (isAnimating || popups.some((popup) => popup.instance.isOpen)) return;

    if (event.deltaY > 0) {
        // Scroll down
        if (isFirstSectionActive) {
            goToContents(animationDuration);
        }
    } else {
        // Scroll up
        if (window.scrollY === 0 && !isFirstSectionActive) {
            goToFirstSection(animationDuration);
        }
    }
}

function onKeydown(event: KeyboardEvent) {
    if (event.keyCode === keyCodes.SPACE && isFirstSectionActive && !popups.some((popup) => popup.instance.isOpen)) {
        goToContents(animationDuration);
    }
}

function onInitialUpWheel(event: WheelEvent) {
    if (event.deltaY < 0) {
        event.preventDefault();
    }
}

function onInitialDownWheel(event: WheelEvent) {
    if (event.deltaY > 0) {
        event.preventDefault();
    }
}

function preventSpaceWhileAnimating(event: KeyboardEvent) {
    if (event.keyCode === keyCodes.SPACE) {
        event.preventDefault();
    }
}

function leaveContent(duration = 1) {
    const leaveTl = gsap.timeline({
        defaults: { duration, ease: 'expo.inOut', overwrite: true },
        onStart: () => {
            document.body.classList.add('content-view--leave');
        },
        onComplete: () => {
            document.body.classList.remove('content-view--leave');
        },
    });

    document.body.classList.add('no-scroll');
    scrollToSection(sections[0], 1.5, 'power2.out');

    leaveTl
        .add(
            hideElement(
                contentAnimationElements.filter(
                    (el) => el.classList.contains('js-content-animation--clip-path') || el.tagName === 'P',
                ),
                duration,
                undefined,
                true,
            ),
        )
        .add(
            hideElement(
                contentAnimationElements.filter(
                    (el) => !(el.classList.contains('js-content-animation--clip-path') || el.tagName === 'P'),
                ),
                duration,
            ),
            0,
        )
        .to('.js-aside', { clipPath: 'inset(0% 100% 0% 0%)' }, 0)
        .to(
            mainTitle,
            {
                scale: 1,
                opacity: 1,
                onComplete: () => {
                    [mainTitle, mainText].forEach((el) => {
                        if (el) {
                            const parent = el.parentElement;

                            if (el) {
                                el.style.position = '';
                            }

                            if (parent) {
                                parent.style.height = '';
                            }
                        }
                    });
                },
            },
            0.3,
        );

    return leaveTl;
}

function enterFirstScreen(duration = 1) {
    const enterTl = gsap.timeline({
        defaults: {
            duration,
            ease: 'expo.inOut',
            overwrite: true,
            onStart: () => {
                document.body.classList.remove('content-view');
                document.body.classList.add('no-scroll', 'first-screen-view');
                window.scrollTo({ top: 0, behavior: 'auto' });
            },
        },
    });

    enterTl
        .to(mainTextBlock, { x: 0 })
        .add(showElement(mainTextBlock, duration).play(), 0)
        .fromTo(
            '.js-main-tag',
            { clipPath: 'inset(0% -10% 100% -10%)', y: -20 },
            { clipPath: 'inset(0% -10% 0% -10%)', y: 0, stagger: 0.05 },
            0,
        )
        .to('.js-main-scroll-indicator', { y: 0, opacity: 1 }, 0);

    return enterTl;
}

export function goToFirstSection(duration = 1.5) {
    const tl = gsap.timeline({
        defaults: { duration, ease: 'expo.inOut' },
        onComplete: () => {
            isAnimating = false;
            document.removeEventListener('keydown', preventSpaceWhileAnimating);
        },
    });

    isAnimating = true;

    if (matchMedia('(min-width: 1025px)').matches) {
        // firstScreenFocusTrap?.activate();
        document.addEventListener('keydown', preventSpaceWhileAnimating);
        tl.add(leaveContent(duration)).add(enterFirstScreen(duration));
    }

    isFirstSectionActive = true;

    return tl;
}

function leaveFirstScreen(duration = 1) {
    const leaveTl = gsap.timeline({
        defaults: { duration, ease: 'expo.inOut', overwrite: true },
        onComplete: () => {
            document.body.classList.remove('first-screen-view--leave');
            document.body.classList.remove('no-scroll', 'first-screen-view');
        },
        onStart: () => {
            document.body.classList.add('first-screen-view--leave');
            document.body.classList.add('content-view');
            document.dispatchEvent(new CustomEvent('set-active-section', { detail: 1 }));
        },
    });

    [mainTitle, mainText].forEach((el) => {
        if (el) {
            const rect = el.getBoundingClientRect();
            const parentEl = el.parentElement;

            if (parentEl) {
                parentEl.style.height = `${rect.height}px`;
                el.style.position = 'fixed';
                el.style.left = `${rect.left}px`;
                el.style.top = `${getOffsetTop(parentEl)}px`;
            }
        }
    });

    leaveTl
        .to(mainTextBlock, { x: '-3vw' })
        .to('.js-aside', { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
        .add(hideElement(mainTextBlock, duration), 0)
        .to('.js-main-tag', { clipPath: 'inset(100% -10% 0% -10%)', yPercent: 100, stagger: 0.05 }, 0)
        .to(mainTitle, { scale: 0.3 }, 0)
        .to('.js-main-scroll-indicator', { y: 30, opacity: 0 }, 0);

    gsap.fromTo('.js-nav', { x: 30 }, { duration, x: 0, delay: duration / 2 });

    return leaveTl;
}

function enterContents(duration = 1) {
    const staggerValue = 0.02;
    const enterTl = gsap.timeline({
        defaults: { duration, ease: 'expo.inOut', overwrite: true },
        onStart: () => {
            const morphContainer = sections[1].querySelector<HTMLElement>('.js-morph');
            const activeDepixelatedEl = morphContainer?.classList.contains('is-depixelated')
                ? morphContainer.querySelector<HTMLElement>('.js-content-pixelated-first.is-active')
                : null;

            if (activeDepixelatedEl) {
                const pixelated = pixelatedEffect.getInstanceByElement(activeDepixelatedEl);

                if (pixelated) {
                    pixelatedEffect.pixelate(activeDepixelatedEl, undefined, 0, true);
                    pixelatedEffect.depixelate(activeDepixelatedEl);
                }
            }

            gsap.fromTo('.js-content-fade-animation', { x: 30 }, { duration: 1, x: 0, delay: 0.3, stagger: 0.1 });
            scrollToSection(sections[1], 0);
        },
    });

    gsap.set('.js-content-fade-animation', { opacity: 0 });

    enterTl
        .fromTo(contentAnimationElements, { x: '-3vw' }, { x: 0, stagger: staggerValue })
        .add(
            showElement(
                contentAnimationElements.filter(
                    (el) => el.classList.contains('js-content-animation--clip-path') || el.tagName === 'P',
                ),
                duration,
                0,
                true,
            ).play(),
            0,
        )
        .add(
            showElement(
                contentAnimationElements.filter(
                    (el) => !(el.classList.contains('js-content-animation--clip-path') || el.tagName === 'P'),
                ),
                duration,
            ).play(),
            0,
        );

    return enterTl;
}

export function goToContents(duration = 1.5) {
    const tl = gsap.timeline({
        defaults: { duration, ease: 'expo.inOut' },
        onComplete: () => {
            isAnimating = false;
            document.removeEventListener('keydown', preventSpaceWhileAnimating);
        },
        onStart: () => {
            const morphContainer = sections[1].querySelector<HTMLElement>('.js-morph');
            const activeDepixelatedEl = morphContainer?.classList.contains('is-depixelated')
                ? morphContainer.querySelector<HTMLElement>('.js-content-pixelated-first.is-active')
                : null;

            if (activeDepixelatedEl) {
                activeDepixelatedEl.style.opacity = '0';
            }
        },
    });

    isAnimating = true;
    // firstScreenFocusTrap?.deactivate();

    if (matchMedia('(min-width: 1025px)').matches) {
        document.addEventListener('keydown', preventSpaceWhileAnimating);
        tl.add(leaveFirstScreen(duration)).add(enterContents(duration));
    }

    isFirstSectionActive = false;

    return tl;
}

const firstSectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            isFirstSectionActive = true;
            if (!isInitialLoad) {
                document.addEventListener('wheel', onInitialDownWheel, { passive: false });
                document.addEventListener('keydown', onKeydown);
                goToFirstSection(animationDuration).then(() => {
                    document.removeEventListener('wheel', onInitialDownWheel);
                    document.addEventListener('keydown', onKeydown);
                });
            } else {
                document.body.classList.add('first-screen-view');

                if (matchMedia('(min-width: 1025px)').matches) {
                    gsap.set('.js-aside', { clipPath: 'inset(0% 100% 0% 0%)' });
                    document.body.classList.add('no-scroll');
                }

                window.scrollTo({ top: 0, behavior: 'auto' });
                // firstScreenFocusTrap?.activate();
            }
        }
    });
});

const appContentObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                isFirstSectionActive = false;
                document.removeEventListener('keydown', onKeydown);

                if (isInitialLoad) {
                    document.addEventListener('wheel', onInitialUpWheel, { passive: false });

                    if (matchMedia('(min-width: 1025px)').matches) {
                        gsap.set([mainTitle, mainText], { opacity: 0 });
                    }

                    document.body.classList.add('content-view');

                    initialAnimation.then(() => {
                        (matchMedia('(min-width: 1025px)').matches ? leaveFirstScreen(0.001) : Promise.resolve()).then(
                            () => {
                                if (matchMedia('(min-width: 1025px)').matches) {
                                    gsap.to([mainTitle, mainText], { duration: 0.2, opacity: 1, delay: 0.1 });
                                }

                                setTimeout(() => {
                                    document.removeEventListener('wheel', onInitialUpWheel);
                                }, 100);
                            },
                        );
                    });
                }
            }
        });
    },
    { rootMargin: '-1px 0px -1px 0px' },
);

function init() {
    if (firstSection) {
        firstSectionObserver.observe(firstSection);
    }

    if (appContent) {
        appContentObserver.observe(appContent);
    }

    document.addEventListener(
        'adult-confirmed',
        () => {
            gsap.fromTo(
                mainScreenAside,
                { opacity: 0, xPercent: 150 },
                { duration: 1, opacity: 1, xPercent: 0, ease: 'expo.out' },
            );
            isInitialLoad = false;
        },
        { once: true },
    );

    document.addEventListener('initial-animation-complete', () => {
        if (Cookie.get('IS_ADULT') === 'true') {
            document.addEventListener('wheel', onWheel);
            document.addEventListener('keydown', onKeydown);
        } else {
            document.addEventListener(
                'adult-confirmed',
                () => {
                    document.addEventListener('wheel', onWheel);
                    document.addEventListener('keydown', onKeydown);
                },
                { once: true },
            );
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
