import gsap from 'gsap';
import { collapseElement, expandElement } from '../animations/collapse';
import { getOffsetTop } from '../utils/dom';

const header = document.querySelector<HTMLElement>('.js-header');
const sections = Array.from(document.querySelectorAll<HTMLElement>('.js-section'));

export function scrollToSection(el: HTMLElement, duration = 1.5, ease = 'expo.inOut') {
    const section = sections.find((section) => section.dataset.sectionName === el.dataset.sectionName);
    const headerHeight = header?.getBoundingClientRect().height || 0;

    if (section) {
        gsap.to(window, {
            duration,
            scrollTo: getOffsetTop(section) - headerHeight - (matchMedia('(max-width: 767px)').matches ? 40 : 0),
            ease,
        });
    }
}

function init() {
    const navigationContainer = document.querySelector<HTMLElement>('.js-nav');
    const paginationContainer = document.querySelector<HTMLElement>('.js-sections-pagination-container');
    const activeNavEl = document.querySelector<HTMLElement>('.js-nav-active');
    let navButtons: HTMLElement[] = [];
    let paginationButtons: HTMLElement[] = [];
    let activeSectionEl: HTMLElement | null;
    let activeNavListItem: HTMLElement | null;
    let prevSectionBtn: HTMLButtonElement | null;
    let nextSectionBtn: HTMLButtonElement | null;

    function setActiveSection(sectionEl: HTMLElement) {
        if (sectionEl === activeSectionEl) return;

        sectionEl.classList.add('is-active');

        if (paginationContainer) {
            const paginationButtons = Array.from(
                paginationContainer.querySelectorAll<HTMLElement>('.js-sections-pagination__btn'),
            );

            paginationButtons.forEach((el) => el.classList.remove('is-active'));
            paginationButtons
                .find((el) => el.dataset.sectionName === sectionEl.dataset.sectionName)
                ?.classList.add('is-active');
        }

        const nextActiveNavBtn = navButtons.find((el) => el.dataset.sectionName === sectionEl.dataset.sectionName);
        const nextActiveNavListItem = nextActiveNavBtn?.closest<HTMLElement>('.nav-list__item');

        collapseElement([navButtons, activeNavEl]).then(() => {
            if (nextActiveNavBtn && activeNavEl) {
                activeNavEl.textContent = nextActiveNavBtn.textContent;
            }

            activeNavListItem?.classList.remove('is-active');

            if (nextActiveNavListItem) {
                nextActiveNavListItem.classList.add('is-active');
                activeNavListItem = nextActiveNavListItem;
            }

            expandElement([navButtons, activeNavEl]);
        });

        const sectionElIndex = sections.indexOf(sectionEl);
        const isPrevSectionExists = !!sections[sectionElIndex - 1];
        const isNextSectionExists = !!sections[sectionElIndex + 1];

        if (prevSectionBtn) {
            prevSectionBtn.disabled = !isPrevSectionExists;
        }

        if (nextSectionBtn) {
            nextSectionBtn.disabled = !isNextSectionExists;
        }

        activeSectionEl = sectionEl;
    }

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target as HTMLElement);
                }
            });
        },
        { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
    );

    const navigationTemplate = `
        <nav class="nav" aria-label="Primary">
            <ul class="list-unstyled nav-list">${sections
                .map(
                    (el) => `
                    <li class="nav-list__item js-nav-list__item">
                        <button class="nav-btn js-nav-btn" data-section-name="${el.dataset.sectionName || ''}">${
                        el.dataset.sectionName || ''
                    }</button>
                    </li>
                `,
                )
                .join('')}</ul>
        </nav>
    `;

    const paginationTemplate = `
        <div class="sections-pagination">
            <button class="section-pagination__nav-btn section-pagination__nav-btn--prev js-section-pagination__nav-btn--prev" aria-label="Go to prev section">
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.21373 11L18 1" stroke="#1A1B1C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <ul class="list-unstyled sections-pagination-list">${sections
                .map(
                    (el) => `
                    <li class="sections-pagination-list__item">
                        <button class="sections-pagination__btn js-sections-pagination__btn" data-section-name="${
                            el.dataset.sectionName || ''
                        }" aria-label="Go to section ${el.dataset.sectionName || ''}"></button>
                    </li>
                `,
                )
                .join('')}</ul>
            <button class="section-pagination__nav-btn section-pagination__nav-btn--next js-section-pagination__nav-btn--next" aria-label="Go to next section">
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9.21373 11L18 1" stroke="#1A1B1C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    `;

    function onNavBtnClick(this: HTMLElement) {
        scrollToSection(this);
    }

    function onPaginationBtnClick(this: HTMLElement) {
        scrollToSection(this);
    }

    if (navigationContainer) {
        navigationContainer.innerHTML = navigationTemplate;
        navButtons = Array.from(navigationContainer.querySelectorAll<HTMLElement>('.js-nav-btn'));
        navButtons.forEach((el) => {
            el.addEventListener('click', onNavBtnClick);
        });
    }

    if (paginationContainer) {
        paginationContainer.innerHTML = paginationTemplate;
        prevSectionBtn = paginationContainer.querySelector<HTMLButtonElement>('.js-section-pagination__nav-btn--prev');
        nextSectionBtn = paginationContainer.querySelector<HTMLButtonElement>('.js-section-pagination__nav-btn--next');

        prevSectionBtn?.addEventListener('click', () => {
            const prevSection = activeSectionEl ? sections[sections.indexOf(activeSectionEl) - 1] : null;

            if (prevSection) {
                scrollToSection(prevSection);
                const isPrevSectionExists = !!sections[sections.indexOf(prevSection) - 1];

                if (prevSectionBtn) {
                    prevSectionBtn.disabled = !isPrevSectionExists;
                }

                if (nextSectionBtn) {
                    nextSectionBtn.disabled = false;
                }
            }
        });

        nextSectionBtn?.addEventListener('click', () => {
            const nextSection = activeSectionEl ? sections[sections.indexOf(activeSectionEl) + 1] : null;

            if (nextSection) {
                scrollToSection(nextSection);
                const isNextSectionExists = !!sections[sections.indexOf(nextSection) + 1];

                if (prevSectionBtn) {
                    prevSectionBtn.disabled = false;
                }

                if (nextSectionBtn) {
                    nextSectionBtn.disabled = !isNextSectionExists;
                }
            }
        });

        paginationButtons = Array.from(
            paginationContainer.querySelectorAll<HTMLElement>('.js-sections-pagination__btn'),
        );
        paginationButtons.forEach((el) => {
            el.addEventListener('click', onPaginationBtnClick);
        });
    }

    document.addEventListener('set-active-section', ({ detail }: any) => {
        if (detail || detail === 0) {
            setActiveSection(sections[detail]);
        }
    });

    sections.forEach((section) => {
        observer.observe(section);
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
