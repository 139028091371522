import { isMobileDevice } from '../utils/is-mobile-device';

async function init() {
    if (!isMobileDevice) {
        const elements = Array.from<HTMLSelectElement>(
            document.querySelectorAll('select.slim-select:not(.slim-select-container--initialized)'),
        );

        if (elements.length > 0) {
            const SlimSelect = await import('slim-select' /* webpackChunkName: "slim-select" */).then((m) => m.default);

            elements.forEach((el) => {
                const instance = new SlimSelect({ select: el, showSearch: false });
                const parent = el.parentElement;
                instance.slim.singleSelected?.container.classList.add('js-aware-btn');

                if (parent?.classList.contains('slim-select-container')) {
                    parent.classList.add('slim-select-container--initialized');
                }
            });
        }
    }
}

const _module = { init };

export default _module;
