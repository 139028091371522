import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = false;
    }

    if (failureBlock) {
        failureBlock.hidden = true;
    }

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = true;
    }

    if (failureBlock) {
        failureBlock.hidden = false;
    }

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    messagesContainer?.classList.remove('form-messages--hidden');
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    messagesContainer?.classList.add('form-messages--hidden');

    setTimeout(() => {
        if (successBlock) {
            successBlock.hidden = true;
        }

        if (failureBlock) {
            failureBlock.hidden = true;
        }
    }, 300);
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    if (forms.length > 0) {
        forms.forEach((form) => {
            let isSubmitting = false;
            const validator = createValidator(form, {
                messages: {
                    en: {
                        EMPTY_FIELD: 'Must be filled',
                    },
                },
                scrollToInvalidInputOptions: {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                },
            });
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ success, message }) => {
                    if (success) {
                        showSuccessMessage(form, message);
                    } else {
                        showFailureMessage(form, message);
                    }
                },
                onError: () => {
                    showFailureMessage(form, 'Произошла ошибка при отправке формы');
                },
                onComplete: () => {
                    showFormMessages(form);
                    setTimeout(() => hideFormMessages(form), 5000);
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                if (isSubmitting) return;
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    isSubmitting = true;
                    sender.send().finally(() => {
                        isSubmitting = false;
                    });
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
