import '../../workers/generate-text-texture.named-worker';
import gsap from 'gsap';
import Aladino from '../../../modules/aladino/src/index';
import vertexShader from './shaders/vertex.glsl';
import fragmentShader from './shaders/fragment.glsl';

function createTextCanvas(el: HTMLElement) {
    const text = el.textContent || '';
    const dpr = Math.min(2, window.devicePixelRatio);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    // const textMeasurement = ctx.measureText(text);
    const styles = getComputedStyle(el);
    const fontSize = parseFloat(styles.fontSize) * dpr;
    const width = parseFloat(styles.width);
    // const width = getPowerOfTwo(textMeasurement.width);
    const height = parseFloat(styles.height);
    // const lineHeight = parseFloat(styles.lineHeight);
    // const height = getPowerOfTwo(fontSize * 2);
    // const maxWidth = createMultilineText(ctx, text, width, textArr);

    canvas.width = width * dpr;
    canvas.height = height * dpr;

    ctx.font = `${styles.fontWeight} ${fontSize}px ${styles.fontFamily}`;
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = styles.color;

    const letterSpacing = parseFloat(styles.letterSpacing) * dpr * 1.04;
    const x = canvas.width / 2;
    const y = canvas.height / 2 + fontSize * (0.12 / dpr);
    const characters = String.prototype.split.call(text, '');
    let index = 0;
    let current: string;
    let currentPosition = x;
    const align = 1;
    let totalWidth = 0;

    for (let i = 0; i < characters.length; i++) {
        totalWidth += ctx.measureText(characters[i]).width + letterSpacing;
    }

    currentPosition = x - totalWidth / 2;

    while (index < text.length) {
        current = characters[index++];
        ctx.fillText(current, currentPosition, y);
        currentPosition += align * (ctx.measureText(current).width + letterSpacing);
    }

    return canvas;
}

const map = new Map<HTMLElement, { material: any; carpet: any; pixelSize: number }>();

function getInstanceByElement(element?: HTMLElement | null) {
    return element ? map.get(element) : undefined;
}

function generateTextureFromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
        const newImg = new Image();
        newImg.hidden = true;
        const url = URL.createObjectURL(blob);

        newImg.onload = () => {
            document.body.removeChild(newImg);
            resolve(url);
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 0);
        };

        newImg.src = url;
        document.body.appendChild(newImg);
    });
}

function generateTexture(el: HTMLElement): Promise<string> {
    return new Promise((resolve) => {
        createTextCanvas(el).toBlob((blob) => {
            if (blob) {
                generateTextureFromBlob(blob).then((url) => resolve(url));
            }
        });
    });
}

function pixelate(el: HTMLElement, pixelSize = 60, duration = 0.6, instant = false): Promise<void> {
    return new Promise((resolve, reject) => {
        const pixelated = getInstanceByElement(el);

        if (!pixelated?.carpet) {
            reject();
            return;
        }

        if (instant) {
            pixelated.carpet.active = true;
            pixelated.carpet.resize();
            gsap.set(pixelated.carpet.material.uniforms, { pixelSize });
            return resolve();
        }

        pixelated.carpet.active = true;

        gsap.to(pixelated.carpet.material.uniforms, {
            duration: 0.6,
            pixelSize,
            ease: `steps(${Math.round(duration * 17)})`,
            onComplete: () => {
                el.style.opacity = '';
                pixelated.carpet.active = false;
                el.dispatchEvent(new Event('pixelate-complete'));
                resolve();
            },
            onStart: () => {
                pixelated.carpet.active = true;
                // pixelated.carpet.resize();
                el.style.opacity = '0';
                el.dispatchEvent(new Event('pixelate'));
            },
            onUpdate: () => {
                pixelated.carpet.resize();
            },
        });
    });
}

function depixelate(el: HTMLElement, duration = 0.6, instant = false): Promise<void> {
    return new Promise((resolve, reject) => {
        const pixelated = getInstanceByElement(el);

        if (!pixelated?.carpet) {
            reject();
            return;
        }

        if (instant) {
            pixelated.carpet.active = true;
            pixelated.carpet.resize();
            gsap.set(pixelated.carpet.material.uniforms, { pixelSize: 1 });
            return resolve();
        }

        pixelated.carpet.active = true;

        gsap.to(pixelated.carpet.material.uniforms, {
            duration,
            pixelSize: 1,
            ease: `steps(${Math.round(duration * 17)})`,
            onComplete: () => {
                el.style.opacity = '';
                pixelated.carpet.active = false;
                el.dispatchEvent(new Event('pixelate-complete'));
                resolve();
            },
            onStart: () => {
                pixelated.carpet.active = true;
                pixelated.carpet.resize();
                el.style.opacity = '0';
                el.dispatchEvent(new Event('pixelate'));
            },
            onUpdate: () => {
                pixelated.carpet.resize();
            },
        });
    });
}

function destroyCarpetByElement(el?: HTMLElement | null) {
    if (!el) return;

    const obj = map.get(el);

    if (obj) {
        obj.carpet.destroy();
        el.classList.remove('gl-initialized');
        el.style.opacity = '';
        map.delete(el);
    }
}

function init() {
    const canvas = document.querySelector<HTMLCanvasElement>('canvas.js-canvas');

    if (!canvas) {
        return;
    }

    let canvasRect = canvas.getBoundingClientRect();
    const sizes = { width: canvasRect.width, height: canvasRect.height };
    const aladino = new Aladino({
        density: 1,
        dpr: Math.min(devicePixelRatio, 2),
        canvas,
        attribs: { antialias: false },
    });

    const material = aladino.material({
        vertex: vertexShader,
        fragment: fragmentShader,
        uniforms: {
            pixelSize: 1,
            maskLine: 1,
        },
    });

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const target = entry.target as HTMLElement;
            const obj = map.get(target);

            if (obj) {
                obj.carpet.active =
                    entry.isIntersecting &&
                    (target.classList.contains('js-morph-el')
                        ? target.classList.contains('is-gl-active') &&
                          !target.closest('.js-morph')!.classList.contains('is-depixelated')
                        : true);
            }
        });
    });

    const glTexts = Array.from(document.querySelectorAll<HTMLElement>('.js-gl-text'));
    let loadedGLTextsCount = 0;

    if (glTexts.length > 0) {
        glTexts.forEach((textEl) => {
            let carpet: any;
            const styles = getComputedStyle(textEl);
            const pixelSize = textEl.dataset.pixelSize
                ? parseFloat(textEl.dataset.pixelSize)
                : parseFloat(styles.fontSize) * 0.75;

            // const material = aladino.material({
            //     vertex: vertexShader,
            //     fragment: fragmentShader,
            //     uniforms: {
            //         pixelSize: 1,
            //         maskLine: 1,
            //     },
            // });

            function onTextureUrlCreated(url: string) {
                loadedGLTextsCount++;
                carpet = aladino.carpet(textEl, {
                    material,
                    uniforms: {
                        image: aladino.texture(url),
                    },
                });

                carpet.resize();
                carpet.active = false;

                map.set(textEl, { material, carpet, pixelSize });
                textEl.classList.add('gl-initialized');

                if (loadedGLTextsCount === glTexts.length) {
                    document.dispatchEvent(new Event('pixelated-initialized'));
                }
            }

            // if ('OffscreenCanvas' in window) {
            //     const offCanvasEl = document.createElement('canvas');
            //     offCanvasEl.width = parseFloat(styles.width) * dpr;
            //     offCanvasEl.height = parseFloat(styles.height) * dpr;
            //     const offscreen = offCanvasEl.transferControlToOffscreen();

            //     const worker = new Worker(`${PUBLIC_PATH}js/${BUILD_TYPE}/generate-text-texture.named-worker.js`, {
            //         type: 'module',
            //     });

            //     worker.addEventListener('message', (event) => {
            //         if (event.data.message === 'texture-created') {
            //             generateTextureFromBlob(event.data.data).then(onTextureUrlCreated);
            //             worker.terminate();
            //         }
            //     });

            //     worker.postMessage(
            //         {
            //             message: 'create-texture',
            //             data: {
            //                 canvas: offscreen,
            //                 dpr,
            //                 text: textEl.textContent!,
            //                 styles: {
            //                     fontSize: styles.fontSize,
            //                     width: styles.width,
            //                     height: styles.height,
            //                     lineHeight: styles.lineHeight,
            //                     fontWeight: styles.fontWeight,
            //                     fontFamily: styles.fontFamily,
            //                     letterSpacing: styles.letterSpacing,
            //                 },
            //             },
            //         },
            //         [offscreen],
            //     );
            // } else {
            generateTexture(textEl).then(onTextureUrlCreated);
            // }

            observer.observe(textEl);
        });
    } else {
        setTimeout(() => {
            document.dispatchEvent(new Event('pixelated-initialized'));
        }, 0);
    }

    Array.from(document.querySelectorAll<HTMLImageElement>('.js-gl-pixelated')).forEach((el) => {
        const pixelSize = el.dataset.pixelSize ? parseFloat(el.dataset.pixelSize) : 60;

        const material = aladino.material({
            vertex: vertexShader,
            fragment: fragmentShader,
            uniforms: {
                pixelSize: 1,
                maskLine: 1,
            },
        });

        const carpet = aladino.carpet(el, {
            material,
            uniforms: {
                image: aladino.texture(el.currentSrc || el.src),
            },
        });

        el.style.opacity = '';
        carpet.active = false;

        map.set(el, { material, carpet, pixelSize });
        el.classList.add('gl-initialized');
        observer.observe(el);
    });

    // const gui = new dat.GUI();
    // gui.addFolder();

    function onResize() {
        if (canvas) {
            canvasRect = canvas.getBoundingClientRect();
            sizes.width = canvasRect.width;
            sizes.height = canvasRect.height;
        }
    }

    window.addEventListener('resize', () => {
        canvasRect = canvas.getBoundingClientRect();
        sizes.width = canvasRect.width;
        sizes.height = canvasRect.height;
    });

    // function destroy() {
    //     onResize();
    // }

    // module.hot?.addDisposeHandler(destroy);
}

function destroy() {
    //
}

const _module = { init, destroy, getInstanceByElement, pixelate, depixelate, destroyCarpetByElement };

export default _module;
