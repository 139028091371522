import serialize from 'form-serialize';
import createValidator from '../../modules/validator';

const labForm = document.querySelector<HTMLFormElement>('form.js-lab-form');
const labFormInput = labForm?.querySelector<HTMLInputElement>('input.js-lab-form-input');
const labFormSubmitBtn = document.querySelector<HTMLElement>('.js-lab-results-submit-btn');
const downloadResultsLink = document.querySelector<HTMLAnchorElement>('a.js-lab-results-download-link');

async function init() {
    if (labForm) {
        const validator = createValidator(labForm);

        labForm.addEventListener('submit', (event) => {
            event.preventDefault();

            if (!labForm) {
                return;
            }

            const isValid = validator.validate();

            if (isValid) {
                const params = serialize(labForm, { hash: true });

                labForm.classList.add('is-loading');

                try {
                    fetch(labForm.action, { method: labForm.method || 'POST', body: JSON.stringify(params) })
                        .then((res) => res.json())
                        .then((response) => {
                            if (response.success) {
                                if (downloadResultsLink) {
                                    downloadResultsLink.href = response.data.filepath;

                                    labFormSubmitBtn?.classList.add('is-hidden');
                                    downloadResultsLink.classList.remove('is-hidden');

                                    labFormInput?.addEventListener(
                                        'input',
                                        () => {
                                            labFormSubmitBtn?.classList.remove('is-hidden');
                                            downloadResultsLink.classList.add('is-hidden');
                                        },
                                        { once: true },
                                    );
                                }
                            } else {
                                throw new Error(response.message);
                            }
                        });
                } catch (err) {
                    throw err;
                } finally {
                    labForm.classList.remove('is-loading');
                }
            }
        });
    }

    if (labFormInput) {
        import('imask' /* webpackChunkName: "IMask" */).then((m) => {
            const IMask = m.default;
            const imask = IMask(labFormInput, { mask: 'CP000000000' });
            labFormInput.classList.add('mask--initialized');
        });
    }
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
