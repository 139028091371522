const observer = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                obs.unobserve(entry.target);
                entry.target.classList.add('is-scroll-revealed');
            }
        });
    },
    { rootMargin: '-10% 0% -10% 0%' },
);

function init() {
    Array.from(document.querySelectorAll('.js-reveal-on-scroll, .content-block p')).forEach((el) => {
        observer.observe(el);
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
