const mapSectionEl = document.querySelector<HTMLElement>('.js-map-section');

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            observer.disconnect();
            import('./map' /* webpackChunkName: "stores-map" */).then((m) => {
                const _module = m.default;
                _module.init();
            });
        }
    });
});

if (mapSectionEl) {
    observer.observe(mapSectionEl);
}
