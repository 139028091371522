import gsap from 'gsap';
import pixelatedEffect from '../inits/pixelated-effect';

const firstSection = document.querySelector<HTMLElement>('.js-first-section');
const horizontalItems = Array.from(document.querySelectorAll<HTMLElement>('.js-animation-horizontal'));
const firstScreenPixelatedTexts = firstSection
    ? Array.from(firstSection.querySelectorAll<HTMLElement>('.js-gl-text'))
    : [];

export const initialAnimation = gsap.timeline({
    paused: true,
    defaults: { ease: 'expo.inOut', duration: 1.5 },
});

initialAnimation
    .from('.js-header', { y: 30 })
    .from(
        '.main-screen-inner',
        {
            y: 30,
            onComplete: () => {
                gsap.set('.main-screen-inner', { clearProps: 'all' });
            },
        },
        0,
    )
    .fromTo(
        '.js-header-animation-item',
        { clipPath: 'inset(100% 0% -10% 0%)' },
        { clipPath: 'inset(-10% 0% -10% 0%)' },
        0,
    )
    .fromTo(
        '.js-main-tag',
        { clipPath: 'inset(100% -10% 0% -10%)', yPercent: -100 },
        { clipPath: 'inset(0% -10% 0% -10%)', yPercent: 0, stagger: 0.1 },
        0,
    )
    .to(
        horizontalItems,
        {
            onComplete: () => {
                horizontalItems.forEach((el) => {
                    el.classList.remove('a-show');
                });
            },
            onStart: () => {
                horizontalItems.forEach((el) => {
                    el.style.setProperty('--content-animation-duration', `${1.5}s`);
                    el.classList.add('a-show');
                });
            },
        },
        0,
    )
    .fromTo('.js-main-text-block', { x: '-3vw' }, { x: 0 }, 0);

document.addEventListener('pixelated-initialized', () => {
    let rAF: number;

    function animate() {
        for (let i = 0; i < firstScreenPixelatedTexts.length; i++) {
            const el = firstScreenPixelatedTexts[i];
            const pixelated = pixelatedEffect.getInstanceByElement(el);

            if (pixelated) {
                pixelated.carpet.resize();
            }
        }

        rAF = requestAnimationFrame(animate);
    }

    initialAnimation.vars.onStart = animate;

    initialAnimation.vars.onComplete = () => {
        cancelAnimationFrame(rAF);
        document.dispatchEvent(new Event('initial-animation-complete'));
    };
});
