import gsap from 'gsap';

type CollapseAnimationProps = {
    duration?: number;
    onComplete?: () => void;
};

const defaultProps: CollapseAnimationProps = { duration: 1 };

export function collapseElement(
    el: gsap.TweenTarget,
    props: Partial<CollapseAnimationProps> = defaultProps,
): Promise<void> {
    return new Promise((resolve) => {
        const options = { ...defaultProps, ...props };
        gsap.to(el, {
            duration: options.duration,
            clipPath: 'inset(50% -10% 50% -10%)',
            ease: 'power3.inOut',
            onComplete: resolve,
            overwrite: true,
        });
    });
}

export function expandElement(
    el: gsap.TweenTarget,
    props: Partial<CollapseAnimationProps> = defaultProps,
): Promise<void> {
    return new Promise((resolve) => {
        const options = { ...defaultProps, ...props };
        gsap.fromTo(
            el,
            { clipPath: 'inset(50% -10% 50% -10%)' },
            {
                duration: options.duration,
                clipPath: 'inset(-10% -10% -10% -10%)',
                ease: 'power3.inOut',
                onComplete: () => {
                    gsap.set(el, { clearProps: 'all' });
                    resolve();
                },
                overwrite: true,
            },
        );
    });
}
