import gsap from 'gsap';
import pixelatedEffect from '../inits/pixelated-effect';

function init() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-morph')).forEach((container) => {
        const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-morph-el'));

        gsap.to(container, {
            '--reveal-value': 0,
            duration: 1.8,
            ease: 'expo.inOut',
            scrollTrigger: {
                trigger: container,
                toggleActions: 'play none none none',
                start: 'center bottom',
                end: 'center bottom+=100',
            },
            onUpdate: () => {
                for (let i = 0; i < elements.length; i++) {
                    const pixelated = pixelatedEffect.getInstanceByElement(elements[i]);

                    if (pixelated?.carpet?.active) {
                        pixelated.carpet.resize();
                    }
                }
            },
            onStart: () => {
                const activeEl = elements.find((el) => el.classList.contains('is-active'));

                if (activeEl) {
                    pixelatedEffect.depixelate(activeEl, 1.5);
                    container.classList.add('is-depixelated');
                }
            },
            onComplete: () => {
                const { morphName } = container.dataset;
                const togglerEl = document.querySelector(`.js-morph-toggler[data-morph-name="${morphName}"]`);
                togglerEl?.classList.add('is-revealed');
            },
        });
    });

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('section-links-block--sticked');
                } else {
                    entry.target.classList.add('section-links-block--sticked');
                }
            });
        },
        { rootMargin: '-40% 0% 0% 0%' },
    );

    Array.from(document.querySelectorAll('.js-section-block')).forEach((blockEl) => {
        const productsTop = blockEl.querySelector<HTMLElement>('.js-products-top');
        const productsRoundsList = blockEl.querySelector<HTMLElement>('.js-products-rounds-list');

        function setProductsRoundsListOffset() {
            if (productsTop) {
                const { height } = productsTop.getBoundingClientRect();
                productsRoundsList?.style.setProperty('--offset', `${height}px`);
            }
        }

        setProductsRoundsListOffset();
        window.addEventListener('resize', setProductsRoundsListOffset);

        const stickyElm = blockEl.querySelector('.js-section-links-block');

        if (stickyElm) {
            observer.observe(stickyElm);
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
