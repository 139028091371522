import pixelatedEffect from './pixelated-effect';

function init() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-morph')).forEach((container) => {
        const name = container.dataset.morphName;
        const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-gl-pixelated'));
        let activeEl = elements.find((el) => el.classList.contains('is-active'));
        const togglers = Array.from(
            document.querySelectorAll<HTMLElement>(`.js-morph-toggler[data-morph-name="${name}"]`),
        );

        if (activeEl) {
            activeEl.classList.add('is-gl-active');
            const obj = pixelatedEffect.getInstanceByElement(activeEl);

            if (obj) {
                obj.carpet.active = true;
                pixelatedEffect.pixelate(activeEl, undefined, undefined, true);
            }
        }

        elements
            .filter((el) => el !== activeEl)
            .forEach((el) => {
                const obj = pixelatedEffect.getInstanceByElement(el);

                if (obj) {
                    obj.carpet.active = false;
                    el.classList.remove('is-gl-active');
                }
            });

        togglers.forEach((toggler) => {
            const togglerTexts = Array.from(toggler.querySelectorAll('.js-morph-toggler-text'));

            toggler.addEventListener('click', () => {
                const nextEl = elements.find((el) => !el.classList.contains('is-active'));
                const nextText = togglerTexts.find((el) => !el.classList.contains('is-active'));

                if (activeEl) {
                    const obj = pixelatedEffect.getInstanceByElement(activeEl);

                    if (obj) {
                        obj.carpet.active = false;
                        activeEl.classList.remove('is-gl-active');
                    }
                }

                if (nextEl) {
                    const obj = pixelatedEffect.getInstanceByElement(nextEl);

                    if (obj) {
                        nextEl.classList.add('is-gl-active');
                        pixelatedEffect.pixelate(nextEl, 100, 0, true);
                        pixelatedEffect.depixelate(nextEl, 0.6);
                    }
                }

                elements.forEach((el) => {
                    el.classList.remove('is-active');
                });

                nextEl?.classList.add('is-active');

                togglerTexts.forEach((el) => {
                    el.classList.remove('is-active');
                });

                nextText?.classList.add('is-active');
                activeEl = nextEl;
            });
        });
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
