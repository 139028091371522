import gsap from 'gsap';
import { hideElement, showElement } from '../animations/reveal';
import { createSliderCounter } from '../components/app-slider/counter';
import { Collapse } from '../custom-elements/Collapse/Collapse';
import { getOffsetTop } from '../utils/dom';
import { withResponse } from './slider';

const header = document.querySelector<HTMLElement>('.js-header');

function init() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-support-slider')).forEach(async (container) => {
        const pagination = container.querySelector<HTMLElement>('.js-support-slider-pagination');
        const slides = Array.from(container.querySelectorAll<HTMLElement>('.js-slide'));
        const paginationItems = pagination
            ? Array.from(pagination.querySelectorAll<HTMLElement>('.js-slider-pagination-btn'))
            : [];
        const questionListItems = Array.from(
            document.querySelectorAll<HTMLElement>('.js-support-questions-list__item'),
        );
        const questionElements = Array.from(document.querySelectorAll<HTMLElement>('.js-support-question'));
        const nextButtons = Array.from(container.querySelectorAll('.js-support-next'));
        const supportCollapse = document.querySelector<Collapse>('app-collapse.js-support-questions-collapse');

        supportCollapse?.addEventListener('expand-complete', () => {
            if (window.CSS?.supports?.('scroll-behavior', 'smooth')) {
                supportCollapse.content?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
            } else if (supportCollapse.content) {
                const headerHeight = header?.getBoundingClientRect().height || 0;
                gsap.to(window, {
                    duration: 1,
                    scrollTo: getOffsetTop(supportCollapse.content) - headerHeight - 70,
                    ease: 'expo.inOut',
                });
            }
        });

        const map = new Map<
            Element,
            {
                titleEl: Element | null;
                imgEl: Element | null;
                nextQuestionEl: Element[];
                textEl: Element[];
                labels: Element[];
            }
        >();

        const groupNames = new Set<string>();
        const groups = new Map<string, HTMLElement[]>();

        if (slides.length > 1) {
            const Slider = await import('../components/app-slider' /* webpackChunkName: "Slider" */).then(
                (m) => m.default,
            );
            let responseData: ReturnType<typeof withResponse> | null;

            slides.forEach((el) => {
                map.set(el, {
                    titleEl: el.querySelector('.js-slider-title'),
                    imgEl: el.querySelector('.js-slider-img'),
                    textEl: Array.from(el.querySelectorAll('.js-slider-text')),
                    labels: Array.from(el.querySelectorAll('.js-slider-label')),
                    nextQuestionEl: Array.from(el.querySelectorAll('.js-slider-next-question')),
                });

                if (el.dataset.group) {
                    groupNames.add(el.dataset.group);
                }
            });

            groupNames.forEach((groupName) =>
                groups.set(
                    groupName,
                    slides.filter((el) => el.dataset.group === groupName),
                ),
            );

            function slideLeave(instance: any): Promise<void> {
                return new Promise((resolve) => {
                    const elements = map.get(instance.slides[instance.activeIndex]);

                    if (elements) {
                        const tl = gsap.timeline({ onComplete: resolve });
                        tl.add(
                            hideElement(
                                elements.titleEl,
                                undefined,
                                undefined,
                                true,
                                responseData?.direction || 'left',
                            ),
                        )
                            .add(
                                hideElement(
                                    elements.labels,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.1,
                            )
                            .add(
                                hideElement(
                                    elements.imgEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.2,
                            )
                            .add(
                                hideElement(
                                    elements.textEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.3,
                            )
                            .add(
                                hideElement(
                                    elements.nextQuestionEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ),
                                0.4,
                            );
                    } else {
                        resolve();
                    }
                });
            }

            function slideEnter(instance: any): Promise<void> {
                return new Promise((resolve) => {
                    const elements = map.get(instance.slides[instance.activeIndex]);

                    if (elements) {
                        const tl = gsap.timeline({ onComplete: resolve });
                        tl.add(
                            showElement(
                                elements.titleEl,
                                undefined,
                                undefined,
                                true,
                                responseData?.direction || 'left',
                            ).play(),
                        )
                            .add(
                                showElement(
                                    elements.labels,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.1,
                            )
                            .add(
                                showElement(
                                    elements.imgEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.2,
                            )
                            .add(
                                showElement(
                                    elements.textEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.3,
                            )
                            .add(
                                showElement(
                                    elements.nextQuestionEl,
                                    undefined,
                                    undefined,
                                    true,
                                    responseData?.direction || 'left',
                                ).play(),
                                0.4,
                            );
                    } else {
                        resolve();
                    }
                });
            }

            const slider = new Slider(container, {
                slideChangeLeaveTransition: slideLeave,
                slideChangeEnterTransition: slideEnter,
            });

            responseData = withResponse(slider, map);
            createSliderCounter(slider);
            slider.slidesContainer?.classList.add('js-cursor--arrowed');
            document.dispatchEvent(new Event('hoverables-added'));

            function setActivePagination() {
                const activeGroup = slides[slider.activeIndex].dataset.group || '';
                const slidesInGroup = groups.get(activeGroup);
                if (slidesInGroup) {
                    paginationItems.forEach((el) => {
                        el.classList.remove('is-active');
                    });
                    paginationItems.find((el) => el.dataset.groupName === activeGroup)?.classList.add('is-active');
                }
            }

            function setActiveQuestion(index: number) {
                questionListItems.forEach((el) => {
                    el.classList.remove('is-active');
                });
                questionListItems[index]?.classList.add('is-active');
            }

            setActivePagination();
            setActiveQuestion(slider.activeIndex);

            slider.on('change-enter', ({ detail: { currentIndex } }) => {
                setActivePagination();
                setActiveQuestion(currentIndex);
            });

            paginationItems.forEach((el) => {
                el.addEventListener('click', () => {
                    const group = el.dataset.groupName || '';
                    const slidesInGroup = groups.get(group);
                    if (slidesInGroup) {
                        slider.navigateTo(slides.indexOf(slidesInGroup[0]));
                    }
                });
            });

            questionElements.forEach((el) => {
                el.addEventListener('click', () => {
                    const parent = el.closest<HTMLElement>('.js-support-questions-list__item');

                    if (parent) {
                        const headerHeight = header?.getBoundingClientRect().height || 0;

                        function scrollOnChange() {
                            gsap.to(window, {
                                duration: 1,
                                scrollTo: getOffsetTop(container) - headerHeight - 50,
                                ease: 'expo.inOut',
                            });
                        }

                        slider.once('change', scrollOnChange);
                        slider.navigateTo(questionListItems.indexOf(parent)).catch(() => {
                            slider.off('change', scrollOnChange);
                        });
                    }
                });
            });

            nextButtons.forEach((btn) => {
                btn.addEventListener('click', () => {
                    const parentSlide = btn.closest<HTMLElement>('.js-slide');

                    if (parentSlide) {
                        const nextSlide = slides[slides.indexOf(parentSlide) + 1];

                        if (nextSlide) {
                            slider.navigateTo(slides.indexOf(nextSlide));
                        }
                    }
                });
            });
        }
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
