import { collapseElement, expandElement } from '../animations/collapse';

function init() {
    let leavePromise: Promise<any> | null;

    Array.from(document.querySelectorAll<HTMLElement>('.js-section-with-counter')).forEach((section) => {
        const counterEl = section.querySelector('.js-counter');
        collapseElement(counterEl, { duration: 0 });

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            expandElement(counterEl, { duration: 0.5 });
                        }, 1);
                    } else {
                        leavePromise = collapseElement(counterEl, { duration: 0.5 });
                        leavePromise.then(() => {
                            leavePromise = null;
                        });
                    }
                });
            },
            { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
        );

        observer.observe(section);
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
