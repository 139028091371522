// import debounce from 'lodash.debounce';
// import delegate from 'delegate';
import gsap from 'gsap';
// import { lerp } from '../utils/math';
// import { viewport } from '../viewport';

export const stickySelector = '[data-cursor-sticker]';
// eslint-disable-next-line max-len
export const hoverableSelector = `a:not(${stickySelector}), button:not(${stickySelector}), input[type="submit"]:not(${stickySelector}), .js-custom-hoverable:not(${stickySelector})`;

export function Cursor() {
    // const lerpValue = 0.09;
    // let gravity = 0.1;
    // let baseScale = 1;
    // let isStuck = false;
    let clientX = 0;
    let clientY = 0;
    // let outerCursorOriginals = {};
    // let outerCursorBox = {};
    // let rAF: number;

    const cursorContainer = document.createElement('div');
    cursorContainer.className = 'cursor js-cursor';

    // const innerCursor = document.createElement('div');
    // innerCursor.className = 'circle-cursor circle-cursor--inner';

    // const outerCursor = document.createElement('div');
    // outerCursor.className = 'circle-cursor circle-cursor--outer';
    // let outerCursorX = viewport.width / 2;
    // let outerCursorY = viewport.height / 2;

    // cursorContainer.appendChild(innerCursor);
    // cursorContainer.appendChild(outerCursor);

    const arrowsCursor = document.createElement('div');
    arrowsCursor.className = 'circle-cursor circle-cursor--arrows';
    arrowsCursor.innerHTML = `
        <svg width="103" height="44" viewBox="0 0 103 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M91.5 13.5L101.5 21.7137L91.5 30.5" stroke="#1A1B1C" stroke-width="1.2"/>
            <circle cx="50" cy="22" r="22" fill="white"/>
            <circle cx="50" cy="22" r="4" fill="#395DAD"/>
            <path d="M11.5 30.5L1.5 22.2863L11.5 13.5" stroke="#1A1B1C" stroke-width="1.2"/>
        </svg>
    `;
    cursorContainer.appendChild(arrowsCursor);
    document.body.appendChild(cursorContainer);

    gsap.set(cursorContainer, { visibility: 'hidden' });

    function getMouseCoords(event: MouseEvent) {
        /* eslint-disable prefer-destructuring */
        clientX = event.clientX;
        clientY = event.clientY;
        /* eslint-enable */
        arrowsCursor.style.transform = `translate3d(${clientX}px, ${clientY}px, 0) translate(-50%, -50%)`;
    }

    // function handleStickyMouseEnter(event: any) {
    //     isStuck = true;
    //     const target = event.delegateTarget;
    //     const box = target.getBoundingClientRect();

    //     gsap.to(outerCursor, {
    //         duration: 0.4,
    //         x: box.left + (box.width - outerCursorOriginals.width) / 2,
    //         y: box.top + (box.height - outerCursorOriginals.height) / 2,
    //         scale: 1.7,
    //         overwrite: true,
    //         borderWidth: 1,
    //     });
    // }

    // function setGravity() {
    //     gravity = Math.max((gravity *= 0.95), 0.1);
    //     if (gravity > 0.1) {
    //         requestAnimationFrame(setGravity);
    //     }
    // }

    // function handleStickyMouseLeave() {
    //     isStuck = false;
    //     gravity = 0.4;
    //     setGravity();
    //     gsap.to(outerCursor, { duration: 0.4, borderWidth: 2 });
    // }

    // function initStickyHovers() {
    //     delegate(document, stickySelector, 'mouseenter', handleStickyMouseEnter, true);
    //     delegate(document, stickySelector, 'mouseleave', handleStickyMouseLeave, true);
    // }

    // function handleMouseEnter() {
    //     // isArrowed = cursorContainer.classList.contains('arrows--visible');
    //     // cursorContainer.classList.remove('arrows--visible');
    //     // gsap.to(innerCursor, { duration: 0.5, opacity: 0.65 });
    // }

    // function handleMouseLeave() {
    //     // if (isArrowed) {
    //     //     cursorContainer.classList.add('arrows--visible');
    //     // }
    //     // gsap.to(innerCursor, { duration: 0.4, opacity: 1 });
    // }

    function handleArrowedCursorEnter() {
        document.documentElement.style.cursor = 'none';
        cursorContainer.classList.add('arrows--visible');
    }

    function handleArrowedCursorLeave() {
        document.documentElement.style.cursor = '';
        cursorContainer.classList.remove('arrows--visible');
    }

    function initHovers() {
        let wasArrow = false;

        Array.from(document.querySelectorAll('.js-cursor--arrowed')).forEach((el) => {
            el.addEventListener('mouseenter', handleArrowedCursorEnter);
            el.addEventListener('mouseleave', handleArrowedCursorLeave);
        });
        Array.from(document.querySelectorAll('a, button, input, select')).forEach((el) => {
            el.addEventListener('mouseenter', () => {
                wasArrow = cursorContainer.classList.contains('arrows--visible');
                handleArrowedCursorLeave();
            });
            el.addEventListener('mouseleave', () => {
                if (wasArrow && el.closest('.js-cursor--arrowed')) {
                    handleArrowedCursorEnter();
                }

                wasArrow = false;
            });
        });
    }

    function destroyHovers() {
        Array.from(document.querySelectorAll('.js-cursor--arrowed')).forEach((el) => {
            el.removeEventListener('mouseenter', handleArrowedCursorEnter);
            el.removeEventListener('mouseleave', handleArrowedCursorLeave);
        });
    }

    // function reset() {
    //     // handleMouseLeave();
    // }

    // function render() {
    //     outerCursorX = lerp(outerCursorX, clientX, lerpValue);
    //     outerCursorY = lerp(outerCursorY, clientY, lerpValue);

    //     outerCursor.style.transform = `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${
    //         outerCursorX - outerCursorBox.halfWidth
    //     },${outerCursorY - outerCursorBox.halfHeight},0,1)`;
    // }

    // function animate() {
    //     render();
    //     rAF = requestAnimationFrame(animate);
    // }

    function unveilCursor() {
        document.removeEventListener('mousemove', unveilCursor);
        // gsap.set(outerCursor, {
        //     x: outerCursorX - outerCursorBox.halfWidth,
        //     y: outerCursorY - outerCursorBox.halfHeight,
        // });
        // animate();
        gsap.set(cursorContainer, { clearProps: 'visibility' });
    }

    // const onResize = debounce(() => {
    //     outerCursorBox = outerCursor.getBoundingClientRect();
    //     outerCursorBox.halfWidth = outerCursorBox.width / 2;
    //     outerCursorBox.halfHeight = outerCursorBox.height / 2;
    //     outerCursorOriginals = {
    //         width: outerCursorBox.width,
    //         height: outerCursorBox.height,
    //     };

    //     outerCursorX = viewport.width / 2;
    //     outerCursorY = viewport.height / 2;

    //     gsap.set(outerCursor, {
    //         x: outerCursorX - outerCursorBox.halfWidth,
    //         y: outerCursorY - outerCursorBox.halfHeight,
    //     });
    // }, 300);

    function init() {
        // outerCursorBox = outerCursor.getBoundingClientRect();
        // outerCursorBox.halfWidth = outerCursorBox.width / 2;
        // outerCursorBox.halfHeight = outerCursorBox.height / 2;
        // outerCursorOriginals = {
        //     width: outerCursorBox.width,
        //     height: outerCursorBox.height,
        // };
        // document.addEventListener('resize', onResize);
        document.addEventListener('mousemove', unveilCursor);
        document.addEventListener('mousemove', getMouseCoords, { passive: true });
        document.addEventListener('hoverables-added', () => {
            destroyHovers();
            initHovers();
        });
        initHovers();
    }

    function destroy() {
        // cancelAnimationFrame(rAF);
        // document.removeEventListener('resize', onResize);
        document.removeEventListener('mousemove', unveilCursor);
        document.removeEventListener('mousemove', getMouseCoords);
        // gsap.set(outerCursor, { scale: 1 });
        // gsap.set(innerCursor, { opacity: 1 });
        destroyHovers();
    }

    return Object.freeze({ init, destroy });
}
