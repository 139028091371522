import delegate from 'delegate';

const directions = { 0: 'top', 1: 'right', 2: 'bottom', 3: 'left' };

const classNames = ['in-top', 'in-right', 'in-bottom', 'in-left', 'out-top', 'out-right', 'out-bottom', 'out-left'];

const getDirectionKey = (event: MouseEvent, node: HTMLElement) => {
    const { width, height, top, left } = node.getBoundingClientRect();
    const l = event.pageX - (left + window.pageXOffset);
    const t = event.pageY - (top + window.pageYOffset);
    const x = l - (width / 2) * (width > height ? height / width : 1);
    const y = t - (height / 2) * (height > width ? width / height : 1);

    return Math.round(Math.atan2(y, x) / (Math.PI / 2) + 5) % 4;
};

function update(this: HTMLElement, event: MouseEvent, prefix: string) {
    this.classList.remove(...classNames);
    this.classList.add(`${prefix}-${directions[getDirectionKey(event, this)]}`);
}

function updateIn(event: any) {
    if (matchMedia('(pointer: fine)').matches) {
        update.call(event.delegateTarget, event, 'in');
    }
}

function updateOut(event: any) {
    if (matchMedia('(pointer: fine)').matches) {
        update.call(event.delegateTarget, event, 'out');
    }
}

delegate(document, '.js-aware-btn', 'mouseover', updateIn);
delegate(document, '.js-aware-btn', 'mouseout', updateOut);
