import gsap from 'gsap';

export function hideElement(
    el: gsap.TweenTarget,
    duration = 1,
    staggerValue = 0,
    useClipPath = false,
    direction = 'left',
) {
    gsap.set(el, { display: 'inline-block' });

    const tween =
        useClipPath && CSS?.supports?.('clip-path', 'inset(0% 0% 0% 0%)')
            ? gsap.to(el, {
                  duration,
                  clipPath: direction === 'left' ? 'inset(-10% 110% -10% -10%)' : 'inset(-10% -10% -10% 110%)',
                  ease: 'power3.inOut',
                  stagger: staggerValue,
              })
            : gsap.to(el, {
                  duration,
                  ease: 'power3.inOut',
                  stagger: staggerValue,
                  onStart: () => {
                      const animate = (el: HTMLElement) => {
                          el.classList.add('content-animation');
                          el.style.setProperty('--content-animation-duration', `${duration}s`);
                          el.classList.add('a-hide');
                          el.classList.remove('a-show');
                      };

                      if (el instanceof HTMLElement) {
                          animate(el);
                      }

                      if (el instanceof Array) {
                          el.forEach((item) => {
                              if (item instanceof HTMLElement) {
                                  animate(item);
                              }
                          });
                      }
                  },
              });

    return tween;
}

export function showElement(
    el: gsap.TweenTarget,
    duration = 1,
    staggerValue = 0,
    useClipPath = false,
    direction = 'right',
) {
    hideElement(el, 0, undefined, useClipPath);

    const animate = (el: HTMLElement) => {
        el.classList.add('content-animation');
        el.style.setProperty('--content-animation-duration', `${duration}s`);
        el.classList.add('a-show');
        el.classList.remove('a-hide');
    };

    const tween =
        useClipPath && CSS?.supports?.('clip-path', 'inset(0% 0% 0% 0%)')
            ? gsap.fromTo(
                  el,
                  {
                      ...(direction === 'left' ? { clipPath: 'inset(-10% -10% -10% 110%)' } : {}),
                  },
                  {
                      duration,
                      clipPath: 'inset(-10% -10% -10% -10%)',
                      ease: 'power3.inOut',
                      paused: true,
                      onComplete: () => {
                          gsap.set(el, { clearProps: 'all' });
                      },
                      stagger: staggerValue,
                  },
              )
            : gsap.to(el, {
                  duration,
                  ease: 'power3.inOut',
                  stagger: staggerValue,
                  paused: true,
                  onStart: () => {
                      const animate = (el: HTMLElement) => {
                          el.classList.add('content-animation');
                          el.style.setProperty('--content-animation-duration', `${duration}s`);
                          el.classList.add('a-show');
                          el.classList.remove('a-hide');
                      };

                      if (el instanceof HTMLElement) {
                          animate(el);
                      }

                      if (el instanceof Array) {
                          el.forEach((item) => {
                              if (item instanceof HTMLElement) {
                                  animate(item);
                              }
                          });
                      }
                  },
              });

    return tween;
}
