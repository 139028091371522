import gsap from 'gsap';

const onMouseleave = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (matchMedia('(pointer: fine)').matches) {
        target.style.setProperty('--mask-x', '-600');
        target.style.setProperty('--mask-y', '-600');
        gsap.to(target, { duration: 0.4, '--mask-scale-mult': 1, ease: 'expo.out' });
    }
};

const onMousemove = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (matchMedia('(pointer: fine)').matches) {
        target.style.setProperty('--mask-x', `${event.offsetX}`);
        target.style.setProperty('--mask-y', `${event.offsetY}`);
    }
};

const onMousedown = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (matchMedia('(pointer: fine)').matches) {
        gsap.to(target, { duration: 0.5, '--mask-scale-mult': 2, ease: 'expo.out', overwrite: true });
    }
};

const onMouseup = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (matchMedia('(pointer: fine)').matches) {
        gsap.to(target, { duration: 0.4, '--mask-scale-mult': 1, ease: 'expo.out', overwrite: true });
    }
};

function init() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-masked')).forEach((el) => {
        const maskedTargetGlElement = el.querySelector<HTMLElement>('.js-masked-target.js-gl-pixelated');
        const simpleTargetGlElement = el.parentElement?.querySelector<HTMLElement>(
            '.js-gl-pixelated:not(.js-masked-target)',
        );

        if (maskedTargetGlElement) {
            maskedTargetGlElement.addEventListener('pixelate', () => {
                el.classList.remove('is-visible');
            });

            maskedTargetGlElement.addEventListener('pixelate-complete', () => {
                if (!simpleTargetGlElement?.classList.contains('is-gl-active')) {
                    el.classList.add('is-visible');
                }
            });
        }

        simpleTargetGlElement?.addEventListener('pixelate', () => {
            el.classList.remove('is-visible');
        });

        el.addEventListener('mouseout', onMouseleave);
        el.addEventListener('mousemove', onMousemove);
        el.addEventListener('mousedown', onMousedown);
        el.addEventListener('mouseup', onMouseup);
    });
}

function destroy() {
    Array.from(document.querySelectorAll<HTMLElement>('.js-masked')).forEach((el) => {
        el.removeEventListener('mouseleave', onMouseleave);
        el.removeEventListener('mousemove', onMousemove);
        el.removeEventListener('mousedown', onMousedown);
        el.removeEventListener('mouseup', onMouseup);
    });
}

const _module = { init, destroy };

export default _module;
