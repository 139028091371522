import gsap from 'gsap';
import { withLeadingZero } from '../utils/strings';

function init() {
    const container = document.querySelector('.js-section-counter');

    if (container) {
        Array.from(document.querySelectorAll<HTMLElement>('.js-section-blocks')).forEach((sectionEl) => {
            const sectionsBlocks = Array.from(sectionEl.querySelectorAll<HTMLElement>('.js-section-block'));
            const counterContainer = document.createElement('div');
            let counterCurrentEl: Element | null;
            let counterNextEl: Element | null;
            let isAnimating = false;
            let lastNextCount = '';
            let realNextCount = '';
            let inQueue = false;

            function change(nextCount: string): Promise<void> {
                return new Promise((resolve) => {
                    lastNextCount = nextCount;
                    inQueue = false;
                    isAnimating = true;

                    if (counterNextEl) {
                        counterNextEl.textContent = nextCount;
                    }

                    gsap.to([counterCurrentEl, counterNextEl], {
                        duration: 0.4,
                        yPercent: () => `+=100`,
                        onComplete: () => {
                            if (counterCurrentEl) {
                                counterCurrentEl.textContent = nextCount;
                                gsap.set([counterCurrentEl, counterNextEl], { clearProps: 'all' });
                            }

                            isAnimating = false;

                            if (inQueue && lastNextCount !== realNextCount) {
                                change(realNextCount);
                            }

                            resolve();
                        },
                    });
                });
            }

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        const target = entry.target as HTMLElement;

                        if (entry.isIntersecting) {
                            const nextCount = `${withLeadingZero(sectionsBlocks.indexOf(target) + 1)}`;

                            if (isAnimating) {
                                realNextCount = nextCount;
                                inQueue = true;
                                return;
                            }

                            if (counterNextEl) {
                                if (nextCount === counterNextEl.textContent) return;
                            }

                            change(nextCount);
                        }
                    });
                },
                { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
            );

            counterContainer.className = 'section-counter js-section-counter';
            counterContainer.innerHTML = `
                <span class="section-counter__current-container">
                    <span class="section-counter__current--current js-section-counter--current">${withLeadingZero(
                        0,
                    )}</span>
                    <span class="section-counter__current--next js-section-counter--next"></span>
                </span>
                — <span class="section-counter__total">${withLeadingZero(sectionsBlocks.length)}</span>
            `;

            container.appendChild(counterContainer);

            counterCurrentEl = counterContainer.querySelector('.js-section-counter--current');
            counterNextEl = counterContainer.querySelector('.js-section-counter--next');

            sectionsBlocks.forEach((el) => {
                observer.observe(el);
            });
        });
    }
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
