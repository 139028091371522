import { collapseElement, expandElement } from '../animations/collapse';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const popupName = 'contact';
const contactPopup = document.querySelector<LitPopupElement>(`app-lit-popup[data-lit-popup="${popupName}"]`);
const contactPopupToggleBtn = document.querySelector<HTMLElement>('.js-contact-popup-toggle-btn');
const contactPopupToggleBtnInner = contactPopupToggleBtn?.querySelector<HTMLElement>(
    '.js-contact-popup-toggle-btn__inner',
);
const defaultText = contactPopupToggleBtnInner?.textContent || '';
let openTimer: number;
let closeTimer: number;

function onOpen() {
    clearTimeout(openTimer);

    if (contactPopupToggleBtnInner) {
        const rect = contactPopupToggleBtn!.getBoundingClientRect();
        collapseElement(contactPopupToggleBtnInner).then(() => {
            contactPopupToggleBtn!.style.minWidth = `${rect.width}px`;
            contactPopupToggleBtnInner.innerHTML = `
                <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.757 3.578L3.578 4.756 8.822 10l-5.244 5.244 1.179 1.179L10 11.178l5.244 5.245 1.178-1.179L11.18 10l5.244-5.244-1.178-1.178L10 8.822 4.757 3.578z" fill="#fff"></path>
                </svg>
                `;
            expandElement(contactPopupToggleBtnInner, { duration: 0.5 });
        });
    }

    openTimer = setTimeout(() => {
        if (contactPopupToggleBtn) {
            contactPopupToggleBtn.removeAttribute('data-lit-popup-open');
            contactPopupToggleBtn.setAttribute('data-lit-popup-close', popupName);
        }
    }, 1);
}

function onClose() {
    clearTimeout(closeTimer);

    if (contactPopupToggleBtnInner) {
        collapseElement(contactPopupToggleBtnInner).then(() => {
            contactPopupToggleBtnInner.textContent = defaultText;
            contactPopupToggleBtn!.style.minWidth = '';
            expandElement(contactPopupToggleBtnInner, { duration: 0.5 });
        });
    }

    closeTimer = setTimeout(() => {
        if (contactPopupToggleBtn) {
            contactPopupToggleBtn.removeAttribute('data-lit-popup-close');
            contactPopupToggleBtn.setAttribute('data-lit-popup-open', popupName);
        }
    }, 1);
}

function init() {
    if (contactPopup) {
        contactPopup.instance.on('open', onOpen);
        contactPopup.instance.on('close', onClose);
    }
}

function destroy() {
    if (contactPopup) {
        contactPopup.instance.off('open', onOpen);
        contactPopup.instance.off('close', onClose);
    }
}

const _module = { init, destroy };

export default _module;
