import { viewport } from '../viewport';

const gravity = 0.5;
const friction = 0.92;

const canvas = document.querySelector<HTMLCanvasElement>('canvas.js-error-canvas');
const dpr = window.devicePixelRatio;
const img = new Image();
img.src = `${PUBLIC_PATH}img/cp.png`;

const sizes = {
    width: viewport.width * dpr,
    height: viewport.height * dpr,
};

class Ball {
    x: number;

    y: number;

    dx: number;

    dy: number;

    radius: number;

    ctx: CanvasRenderingContext2D;

    constructor(ctx: CanvasRenderingContext2D, x: number, y: number, dx: number, dy: number, radius: number) {
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.radius = radius;
        this.ctx = ctx;
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.drawImage(img, this.x, this.y, this.radius, this.radius);
    }

    update() {
        if (this.y + this.radius + this.dy > sizes.height) {
            this.dy = -this.dy;
            this.dy = this.dy * friction;
            this.dx = this.dx * friction;
        } else {
            this.dy += gravity;
        }

        if (this.x + this.radius >= sizes.width || this.x - this.radius <= 0) {
            this.dx = -this.dx * friction;
        }

        this.x += this.dx;
        this.y += this.dy;
        this.draw();
    }
}

if (canvas) {
    const c = canvas.getContext('2d');

    canvas.width = sizes.width;
    canvas.height = sizes.height;

    window.addEventListener('resize', () => {
        sizes.width = viewport.width * dpr;
        sizes.height = viewport.height * dpr;
        canvas.width = sizes.width;
        canvas.height = sizes.height;
    });

    function randomIntFromRange(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const ballArray: any[] = [];
    const radius = (matchMedia('(max-width: 1024px)').matches ? 30 : 40) * dpr;

    function getBallsAmount() {
        if (matchMedia('(max-width: 767px)').matches) {
            return 30;
        }

        if (matchMedia('(max-width: 1024px)').matches) {
            return 40;
        }

        if (matchMedia('(max-width: 1440px)').matches) {
            return 70;
        }

        return 100;
    }

    function init() {
        for (let i = 0; i < getBallsAmount(); i++) {
            const x = randomIntFromRange(radius, sizes.width - radius);
            const y = randomIntFromRange(0, sizes.height - radius);
            const dx = randomIntFromRange(-3, 3);
            const dy = randomIntFromRange(-2, 2);

            if (c) {
                ballArray.push(new Ball(c, x, y, dx, dy, radius));
            }
        }
    }

    function animate() {
        c?.clearRect(0, 0, sizes.width, sizes.height);

        for (let i = 0; i < ballArray.length; i++) {
            ballArray[i].update();
        }

        requestAnimationFrame(animate);
    }

    init();
    animate();
}
