import _styles from './AutocompleteInput.ce.scss';
import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map';
import { unsafeHTML } from 'lit/directives/unsafe-html';
import debounce from 'lodash.debounce';
import keyCodes from '../../utils/keyCodes';

type Suggestion = {
    value: string;
    markup: string;
};

export interface AutocompleteInput {
    endpoint: string;
    loading: boolean;
    data: string;
    requestData: Record<string, any>;
    _input: HTMLInputElement;
    _isSuggestionsVisible: boolean;
    _suggestions: Suggestion[];
    _debouncedOnChange: (event: any) => void;
    _abortController: AbortController | undefined;
    _enterPressed: boolean;
}

export class AutocompleteInput extends LitElement {
    constructor() {
        super();
        this._onInput = this._onInput.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);
        this._onInputKeydown = this._onInputKeydown.bind(this);

        this._suggestions = [];
        this._enterPressed = false;
    }

    static get properties() {
        return {
            endpoint: {
                type: String,
                reflect: true,
            },
            data: {
                type: String,
                reflect: true,
            },
            loading: {
                type: Boolean,
                value: false,
            },
            _isSuggestionsVisible: {
                type: Boolean,
                value: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this._input = this.querySelector('input') as HTMLInputElement;
        this._input.setAttribute('role', 'textbox');
        this._input.setAttribute('aria-autocomplete', 'list');
        this._input.setAttribute('aria-multiline', 'false');
        this._input.setAttribute('autocomplete', 'off');

        this._debouncedOnChange = debounce(async () => {
            if (!this._input.value.trim()) return;

            this._abortController = new AbortController();
            this.loading = true;

            try {
                const response = await fetch(this.endpoint, {
                    signal: this._abortController.signal,
                    method: 'post',
                    body: JSON.stringify({ ...this.requestData, query: this._input.value.trim() }),
                }).then((res) => res.json());

                if (response.success) {
                    this._suggestions = response.data;

                    if (!this._enterPressed) {
                        this._isSuggestionsVisible = true;
                    }
                } else {
                    throw new Error(response.message);
                }
            } catch (err) {
                if (err.name !== 'AbortError') {
                    throw err;
                }
            } finally {
                this.loading = false;
                this._enterPressed = false;
            }
        }, 600);

        this._input.addEventListener('input', this._onInput);
        this._input.addEventListener('keydown', this._onInputKeydown);
        document.addEventListener('click', this._closeOnOutsideClick);
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        super.attributeChangedCallback(name, oldValue, newValue);

        if (name === 'data') {
            this.requestData = this.data ? JSON.parse(this.data) : {};
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this._abortController) {
            this._abortController.abort();
            this._abortController = undefined;
        }

        this._input.removeEventListener('input', this._onInput);
        this._input.removeEventListener('keydown', this._onInputKeydown);
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    protected _onSuggestionClick(event: any) {
        const btn = event.target.closest('.suggestion-btn');

        if (btn?.dataset.value.trim()) {
            this._input.value = btn.dataset.value.trim();
        }

        this._isSuggestionsVisible = false;
        this.dispatchEvent(
            new CustomEvent('choice', {
                composed: true,
                detail: { value: this._input.value, triggerElement: btn },
            }),
        );
    }

    protected _onSuggestionKeydown(event: any) {
        // if (event.keyCode === KEYCODES.ARROW_UP) {
        //     event.preventDefault();
        //     if (event.target.parentElement.previousElementSibling) {
        //         event.target.parentElement.previousElementSibling.querySelector('.suggestion-btn')?.focus();
        //     } else {
        //         this._input?.focus();
        //     }
        // }
        // if (event.keyCode === KEYCODES.ARROW_DOWN) {
        //     event.preventDefault();
        //     event.target.parentElement.nextElementSibling?.querySelector('.suggestion-btn')?.focus();
        // }
    }

    protected _onInputKeydown(event: any) {
        if (event.keyCode === keyCodes.ENTER) {
            this._isSuggestionsVisible = false;
            this._enterPressed = true;
        }
    }

    protected _closeOnOutsideClick(event: any) {
        if (!event.target.closest('.autocomplete')) {
            this._isSuggestionsVisible = false;
        }
    }

    protected _onInput(event: any) {
        this._input.value = event.target.value;
        this._abortController?.abort();
        this._debouncedOnChange(event);
    }

    render() {
        return html`
            <div class="input-row">
                <slot></slot>
            </div>
            <div
                class="${classMap({
                    'loader-container': true,
                    'loader-container--visible': this.loading,
                })}"
            >
                <div class="loader" role="status">
                    <span class="visually-hidden">Загрузка...</span>
                </div>
            </div>
            <div
                class="${classMap({
                    dropdown: true,
                    'dropdown--opened': this._isSuggestionsVisible,
                })}"
            >
                <ul class="list">
                    ${this._suggestions.map(
                        (suggestion) => html`
                            <li class="list__item">
                                <button
                                    type="button"
                                    class="suggestion-btn"
                                    tabindex="-1"
                                    @click="${this._onSuggestionClick}"
                                    @keydown="${this._onSuggestionKeydown}"
                                    data-value="${suggestion.value}"
                                >
                                    ${html`${unsafeHTML(suggestion.markup)}`}
                                </button>
                            </li>
                        `,
                    )}
                </ul>
            </div>
        `;
    }
}

customElements.define('x-autocomplete-input', AutocompleteInput);

declare global {
    interface HTMLElementTagNameMap {
        'x-autocomplete-input': AutocompleteInput;
    }
}
