import { init } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

/* global NODE_ENV SENTRY_DSN */
if (NODE_ENV === 'production' && SENTRY_DSN) {
    init({
        dsn: SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
}
